import * as React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"

import ReactPlayer from 'react-player'

const videoList = [
  {
    url: "https://vimeo.com/560458954/a7f34280eb",
    title: "BoomReader - Parental Engagement",
    desc: "Join Russell and special guest Jenny Byrne, headteacher of SS Peter and Paul Primary Catholic Academy, Wolverhampton to discuss how to maximise parental engagement."
  },
  {
    url: "https://vimeo.com/563283405/aa02d68527",
    title: "Tips & Tricks",
    desc: "Catch up on the live webinar to learn some tips and tricks on how to get the best out of your digital reading records."
  },
  {
    url: "https://vimeo.com/566115025/4a603cfc6d",
    title: "Supporting Reading Strategies",
    desc: "Learn how to use BoomReader to support reading strategies in school with Verity Graham, English lead, Pinfold Street JMI School, Walsall"
  },
  {
    url: "https://vimeo.com/568927561/9b652190c8",
    title: "Pupil Engagement",
    desc: "A discussion around pupil use of BoomReader and the benefits. Also an opportunity to learn more about the GoReadathon - a reading event for the summer break."
  }
]

const Training = () => (
  <Layout>
    <Seo title="Training" />
    <div className="container">
      <h1>Training</h1>

      <section className="featuredVideo">
        <div className="video">
          <div className="video_layout">
            <ReactPlayer
              className="video_player"
              width="100%"
              height="100%"
              url="https://vimeo.com/799442301/0427a1abc2"
              controls={true}
              config={{
                  vimeo: {
                      playerOptions:{
                          title: false,
                          byline: false,
                      }
                  }
              }} />
          </div>
        </div>
        <div className="featuredVideo_desc">
          <h2 className="featuredVideo_title">BoomReader Staff Training</h2>
          <p>This short recorded webinar will walk you through the main features of BoomReader and how to use it with your class.</p>
        </div>
      </section>

      <h2 className="txt-center">Other Recorded Training Sessions</h2>
      <div className="videoList">
        {videoList.map(v => (
          <React.Fragment key={v.url}>
            <div className="videoListItem">
              <div className="video">
                <div className="video_layout">
                  <ReactPlayer
                    className="video_player"
                    width="100%"
                    height="100%"
                    url={v.url}
                    controls={true}
                    config={{
                        vimeo: {
                            playerOptions:{
                                title: false,
                                byline: false,
                            }
                        }
                    }} />
                </div>
              </div>
              <div className="videoListItem_info">
                  <h2 className="videoListItem_title">{v.title}</h2>
                  <p className="videoListItem_desc">{v.desc}</p>
                </div>
            </div>
          </React.Fragment>
        ))}
      </div>

    </div>
  </Layout>
)

export default Training